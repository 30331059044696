import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, IconHeartFilled, IconHeart, ReviewRating } from '../../components';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import config from '../../config';

import css from './ListingPage.module.css';

const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    richTitle,
    category,
    authorLink,
    showContactUser,
    onContactUser,
    isMobileLayout,
    bookmarks,
    handleWishlist,
    id,
    reviewCount
  } = props;

  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingPage.perNight'
    : isDaily
    ? 'ListingPage.perDay'
    : 'ListingPage.perUnit';

  return (
    <div className={css.sectionHeading}>
      <div className={css.desktopPriceContainer}>
        <div className={css.desktopPriceValue} title={priceTitle}>
          {formattedPrice}
        </div>
        <div className={css.desktopPerUnit}>
          <FormattedMessage id={unitTranslationKey} />
        </div>
      </div>
      <div className={css.heading}>
      <div className={css.bookmarkContainer}>
        <h1 className={css.title}>{richTitle}</h1>
        {bookmarks && bookmarks.findIndex(e => e == id) > -1 ? (
          <span className={css.bookmark} onClick={e => { handleWishlist(id, e) }}>
            <IconHeartFilled />
          </span>
        ) : (
          <span className={css.bookmark} onClick={e => { handleWishlist(id, e) }}>
            <IconHeart />
          </span>
        )}
      </div>
        <div className={css.author}>
          {/* {category} */}
          {/* <FormattedMessage id="ListingPage.soldBy" values={{ name: authorLink }} /> */}
          {/* {showContactUser ? (
            <span className={css.contactWrapper}>
              <span className={css.separator}>•</span>
              <InlineTextButton
                rootClassName={css.contactLink}
                onClick={onContactUser}
                enforcePagePreloadFor="SignupPage"
              >
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null} */}
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
